import { render, staticRenderFns } from "./UpdateVersion.vue?vue&type=template&id=16d4b35e&scoped=true"
import script from "./UpdateVersion.vue?vue&type=script&setup=true&lang=js"
export * from "./UpdateVersion.vue?vue&type=script&setup=true&lang=js"
import style0 from "./UpdateVersion.vue?vue&type=style&index=0&id=16d4b35e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16d4b35e",
  null
  
)

export default component.exports